import React, { useEffect } from 'react';
import { isMobile, bounds } from '@klickinc/kf-react-components';
// Structure imports
import Layout from '@components/structure/Layout';
import Seo from '@components/utility/SEO';
import { KfAnchorLink } from '@klickinc/kf-react-components';
import Hero from '@components/content/hero/Hero';
import Image from '@components/utility/Image';
import './styles.scss';

const CookiePolicy = () => {
	const runScreenshots = () => {
		if (typeof window !== 'undefined') {
			const url = window.location.href;

			if (url.includes('ssCookiePolicyPopup')) {
				document.getElementById('personalizedAds').checked = true;
				document.getElementById('submit').onsubmit = true;
			}

			if (url.includes('ssCookiePopup')) {
				document.querySelector("html").classList.add('overFlow');

				const cookieCheck = setInterval(() => {
					const cookie = document.body.querySelector("#ot-ccpa-optout");

					if (cookie){
						cookie.classList.add('open');
						clearInterval(cookieCheck);
					}
				}, 100);
			}
		}
	};

	useEffect(() => {
		let cookieBtn = null;
		let cookieCheck = null;
		const cookieBtnClickHandler = () => {
			window?.OneTrust.ToggleInfoDisplay();
		}

		if (typeof window !== 'undefined') {
			if (window.location.href.indexOf('ed=1') > -1) {
				runScreenshots();
			}

			cookieCheck = setInterval(() => {
				cookieBtn = document.querySelector('.cookieBtn');

				if (cookieBtn) {
					cookieBtn.addEventListener('click', cookieBtnClickHandler);
					clearInterval(cookieCheck);
				}
			}, 100);
		}

		return () => {
			if (typeof window !== 'undefined') {
				if (cookieBtn) cookieBtn.removeEventListener('click', cookieBtnClickHandler);
				if (cookieCheck) clearInterval(cookieCheck);
			}
		}
	}, []);

	return (
		<Layout>
			<Seo title="Cookie Policy | EMFLAZA® (deflazacort)" metaRobots="noindex"/>
			<div className='relative min-h-screen page--cookie-policy'>
				<Hero
					heroImage='cookie-policy-hero.png'
					heroImageMobile='cookie-policy-hero.png'
					title='Cookie Policy'
					objectFit='contain'
					addedClass='blue-bg'
					useBreadcrumbs={false}
				/>
				<div className='absolute right-0 hidden md:block z-negative'>
					<Image imageName='cookie-policy-anchored-deco.png'></Image>
				</div>
				<div className='px-8 pt-12 mb-8 text-left md:mb-56 lg:px-20 sm:max-w-5xl'>
					<div>
						<h2>Cookies</h2>
						<br />
						<p>
							A cookie is a small piece of data (text file) that a website —
							when visited by you — asks your browser to store on your
							device in order to remember information about you. Certain
							cookies are set by us and called first party cookies. Our
							first party cookies may collect information about your usage
							of our Site, such as time spent on the Site, pages visited,
							language preferences, and other traffic data. <br /><br /> We also use
							third party cookies — which are cookies from a domain
							different than the domain of the website you are visiting —
							for our advertising and marketing efforts. The parties that
							set these third party cookies can recognize your computer or
							device both when it visits our Site and also when it visits
							certain other sites and/or apps. We do not control how these
							third parties use your information, nor do we retain access to
							the information collected by third party cookies. The
							information is controlled wholly by that third party and is
							subject to the third party's own privacy policy. <br /><br /> You can
							control the way in which cookies are used by altering your
							browser settings. You may refuse to accept cookies by
							activating the setting on your browser that allows you to
							reject cookies. However, if you select such a setting, this
							may affect the functioning of our Site. Unless you have
							adjusted your browser setting so that it will refuse cookies,
							our system will issue cookies when you access or log on to our
							Site. To find out more about cookies, including how to see
							what cookies have been set and how to manage and delete them,
							visit <KfAnchorLink  url="https://www.aboutcookies.org" target="_blank">www.aboutcookies.org</KfAnchorLink> or <KfAnchorLink  url="https://www.allaboutcookies.org" target="_blank">www.allaboutcookies.org</KfAnchorLink>. <br /> <br /> We use
							the information collected by these technologies for security
							purposes, to facilitate navigation, to personalize and improve
							your experience while using the Site, to improve and measure
							our advertising campaigns and to better reach users with
							relevant advertising both on our Site and on third party
							websites. We also gather statistical information about use of
							the Site in order to continually improve its design and
							functionality, understand how they are used and assist us with
							resolving questions regarding them. Do Not Track signals.
							Please note that our Site does not recognize or respond to any
							signal which your browser might transmit through the so-called
							'Do Not Track' feature your browser might have. If you wish to
							disable cookies on our Site, you should not rely on any 'Do
							Not Track' feature your browser might have. For more
							information about Do Not Track signals, please <KfAnchorLink url="https://allaboutdnt.com"  target="_target">click here</KfAnchorLink>.
						</p>
						<br />
					</div>
					<p>We use the following types of cookies:</p>
					<br />
					<div>
						<h2>Strictly Necessary Cookies</h2>
						<br />
						<p>
							These cookies are necessary for the website to function and
							cannot be switched off in our systems. You can set your
							browser to block or alert you about these cookies, but then
							some parts of the Site will not work.
						</p>
						<br />
					</div>
					<div>
						<h2>Performance Cookies</h2>
						<br />
						<p>
							These cookies allow us to count visits and traffic sources, so
							we can measure and improve the performance of our Site. They
							help us know which pages are the most and least popular and
							see how visitors move around the Site. If you do not allow
							these cookies, we will not know when you have visited our
							Site.
						</p>
						<br />
					</div>
					<div>
						<h2>Targeting Cookies</h2>
						<br />
						<p>
							These cookies may be set through our Site by our advertising
							partners. They may be used by those companies to build a
							profile of your interests and show you relevant advertisements
							on other sites. If you do not allow these cookies, you will
							experience less targeted advertising.
						</p>
						<br />
					</div>
					<div>
						<h2>Opting Out of Cookies</h2>
						<br />
						<p>
							Under the California Consumer Privacy Act (CCPA), a "sale"
							includes disclosing or making available personal information
							to a third party in exchange for monetary compensation or
							other benefits or value. While we do not disclose personal
							information to third parties in exchange for monetary
							compensation, we may disclose or make available certain
							categories of personal information to third parties in order
							to receive certain benefits or services, such as when we make
							browsing information available to third party ad companies
							(through third party tags on our Sites) in order to improve
							and measure our ad campaigns and reach users with more
							relevant ads and content. As defined by the CCPA, we may
							"sell" certain categories of personal information as follows:
						</p>
						<br />
					</div>
					<div>
						<ol className="bulleted-list">
							<li>
								Usage Data and Identifiers to third party advertising networks
								and data analytics providers; and
							</li>
							<li>
								Identifiers to third party marketing partners who may contact
								you about products and services you may be interested in.
							</li>
						</ol>
					</div>
					<br />
					<p>
						Users in California may opt out of the sale of their personal
						information by clicking this link <br className='inline md:hidden'/>“<button className="cookieBtn"><span className='underline'>Do Not Sell My Personal
						Information</span></button>”.
					</p>
					<br />
					<div className='absolute right-0 hidden sm:block z-negative 2xl:-left-8'>
						<Image imageName='cookie-policy-anchored-deco2.png'></Image>
					</div>
					<br />

					<div>
						<p>
							Most browsers also allow you to refuse to accept cookies and
							to delete cookies. The methods for doing so vary from browser
							to browser, and from version to version. You can however
							obtain up-to-date information about blocking and deleting
							cookies via these links:
						</p>
						<br />
						<ol className='list-decimal'>
							<li className="break-words">
								<KfAnchorLink target="_blank" url='https://support.google.com/chrome/answer/95647'>
									https://support.google.com/chrome/<br className='md:hidden' />answer/95647
								</KfAnchorLink>&nbsp;(Chrome)
							</li>
							<li>
								<KfAnchorLink target="_blank" url='https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences '>
									https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences				</KfAnchorLink>&nbsp;(Firefox)
							</li>
							<li>
								<KfAnchorLink target="_blank" url='https://help.opera.com/en/latest/security-and-privacy/ '>
									https://help.opera.com/en/latest/security-and-privacy/
								</KfAnchorLink>&nbsp;(Opera)
							</li>
							<li>
								<KfAnchorLink target="_blank" url='https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies '>
									https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies
								</KfAnchorLink>&nbsp;(Internet Explorer)
							</li>
							<li>
								<KfAnchorLink target="_blank" url='https://support.apple.com/en-gb/guide/safari/manage-cookies-and-website-data-sfri11471/mac '>
									https://support.apple.com/en-gb/guide/safari/manage-cookies-and-website-data-sfri11471/mac
								</KfAnchorLink>&nbsp;(Safari)
							</li>
							<li>
								<KfAnchorLink target="_blank" url='https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy '>
									https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy						</KfAnchorLink>&nbsp;(Edge)
							</li>
						</ol>
					</div>
					<br />
					<div>
						<p>
							Blocking all cookies will have a negative impact upon the
							usability of many websites. If you block cookies, you will not
							be able to use all the features on our Site.
						</p>
						<br />
						<h2>Analytics</h2>
						<br />
						<p>
							We use automated devices and applications, such as Google
							Analytics, to evaluate usage of our Site. We use these tools
							to help us improve the performance of our website and our
							user experience on our website. These entities may use
							cookies and other tracking technologies, such as pixels or
							local storage objects (LSOs), to perform their services. To
							learn more about Google's privacy practices, please review the
							Google Privacy Policy at <KfAnchorLink  url="https://www.google.com/policies/privacy/" target="_blank"> https://www.google.com/policies/privacy/</KfAnchorLink>. You can also
							download the Google Analytics Opt-out Browser Add-on to
							prevent your data from being used by Google Analytics at <KfAnchorLink  url="https://tools.google.com/dlpage/gaoptout/" target="blank">https://tools.google.com/dlpage/gaoptout</KfAnchorLink>.
						</p>
						<br />
						<h2>Ad Network</h2>
						<br />
						<p>
							We use network advertisers to serve advertisements on
							non-affiliated websites or other media (eg, social networking
							platforms). This enables us and these network advertisers to
							target advertisements to you for products and services in
							which you might be interested. Ad network providers,
							advertisers, sponsors and/or traffic measurement services may
							use cookies, JavaScript, pixels, LSOs and other tracking
							technologies to measure the effectiveness of their ads and to
							personalize advertising content to you. These cookies and
							other technologies are governed by each entity's specific
							privacy policy, not this one. We may provide these advertisers
							with information, including personal information, about you.
						</p>
						<br />
						<p>
							Users may opt out of many ad networks. For example, you may go
							to the Digital Advertising Alliance ("DAA") <KfAnchorLink url="https://optout.aboutads.info/" target="_blank">Consumer Choice
							Page</KfAnchorLink> for information about opting out of interest-based
							advertising and their choices regarding having information
							used by <KfAnchorLink url="https://youradchoices.com/learn" target="_blank">DAA companies</KfAnchorLink>. You may also go to the Network
							Advertising Initiative ("NAI") <KfAnchorLink url="https://optout.networkadvertising.org/" target="_blank">Consumer Opt-Out Page</KfAnchorLink> for
							information about opting out of interest-based advertising and
							their choices regarding having information used by <KfAnchorLink url="https://www.networkadvertising.org/participating-networks" target="_blank">
								NAI
								members
							</KfAnchorLink>.
						</p>
						<br />
						<p>
							Opting out from one or more companies listed on the DAA&nbsp;
							<KfAnchorLink url="https://optout.aboutads.info/" target="_blank">Consumer Choice Page</KfAnchorLink> or the NAI <KfAnchorLink url="https://optout.networkadvertising.org/" target="_blank">Consumer Opt-Out Page</KfAnchorLink> will opt
							you out from those companies' delivery of interest-based
							content or ads to you, but it does not mean you will no longer
							receive any advertising through our website or on other
							websites. You may continue to receive advertisements, for
							example, based on the particular website that you are viewing
							(ie, contextually based ads). Also, if your browsers are
							configured to reject cookies when you opt out on the DAA or
							NAI websites, your opt out may not be effective. Additional
							information is available on the DAA's website at&nbsp;
							<KfAnchorLink url="https://www.aboutads.info" target="_blank">www.aboutads.info</KfAnchorLink> or the NAI's website at <KfAnchorLink url="https://www.networkadvertising.org" target="_blank">www.networkadvertising.org</KfAnchorLink>.
						</p>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default CookiePolicy;
